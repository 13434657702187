@import '../variables.scss';

.profile {
    .account-container {
        margin: 30px auto 20px;
        padding: 20px 10px 30px;
        color: black;
        border: 1px solid grey;
        border-radius: 10px;
        background-color: white;
        max-width: 650px;
        button {
            margin-bottom: 15px;
        }
        .link-container {
            margin-top: 20px;
            .shopping-link {
                color: green;
                border: 1px solid green;
                padding: .375rem .75rem;
                border-radius: 5px;
            }
        }
        @media screen and (max-width: 768px) {
            .profile-photo {
                max-width: 100px;
                padding-bottom: 15px;
            }
            .profile-body {
                text-align: center;
            }
        }
        .profile-photo {
            max-width: 175px;
            margin: auto;
        }
        .profile-photo {
            display: flex;
        }
        .progress-page-link {
            border: 1px solid #007bff;
            padding: .375rem .75rem;
            border-radius: 5px;
        }
        @media (max-width: 767px) {
            .progress-page-link {
                text-align: center;
            }
        }
    }
    .profile-posts {
        max-width: 825px;
        margin: auto;
    }
    .blog-posts-header {
        color: white;
        text-align: center;
    }    
}