.comment-input {
    padding: 3px 5px;
    border: 1px solid white;
    border-radius: 5px;
    resize: none;
    color: white;
    background-color: #222;
    width: 50%;
    margin-left: 20px;
    &:focus {
        background-color: #fff;
        border-color: grey;
        color: black;
    }
    &::placeholder {
        color: white;
    }
}

.new-comment {
    width: 100%;
    display: inline-flex;
    margin-bottom: 10px;
    button {
        margin: auto 0px auto 10px;
    }
}

@media screen and (max-width: 767px) {
    .comment-input {
        width: 100%;
        margin-left: 0px;
    }
}