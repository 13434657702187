.comment {
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0px 20px 10px;
    background-color: #222222;
    width: 66%;
    color: white;
    hr {
        margin: 0px;
    }
    a {
        color: white;
    }
    .three-dots {
        margin-top: -3px;
        margin-right: -6px;
        cursor: pointer;
    }    
    .comment-name {
        float: left;
        font-style: italic;
    }
    .comment-date {
        text-align: right;
    }
    .comment-body {
        overflow: auto;
    }
}

@media screen and (max-width: 767px) {
    .comment {
        width: 100%;
        margin-left: 0px;
    }
}