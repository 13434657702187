.footer {
    margin-top: 50px;
    ul {
        list-style: none;
        display: flex;
        justify-content: center;
        text-align: center;
        padding-left: 0px;
    }
    nav {
        margin-left: auto;
        margin-right: auto;
    }
    li {
        padding: 2px 8px;
        margin: 2px 10px;
        a {
            font-weight: bold;
            font-size: 20px;
            &:hover {
                color: #fff
            }
        }
    }
    .site-info-text {
        text-align: center;
        padding-bottom: 20px;
        a {
            color: black;
            font-weight: bold;
        }
    }
}

@media (min-width: '768px') {
    .footer {
        .site-info-text {
            color: white;
            a {
                color: white;
            }
        }
    }
    li {
        a {
            color: white;
        }
    }
    .media-logo {
        color: white;
    }
}