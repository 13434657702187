.FAQs {
    color: white;
    .FAQs-header {
        margin-top: 20px;
        text-align: center;
    }
    .tabset {
        margin-bottom: 20px;
        justify-content: center;
        list-style: none;
        display: flex;
        text-align: center;
        .tab {
            color: white;
            font-size: 20px;
            text-decoration: none;
            &:hover{
                color: white;
            }
        }
    }
    .FAQ-nav {
        background-color: #000;
        padding: 2px 8px;
        border-radius: 4px;
        border: 1px solid white;
        margin: 5px;
    }
    .FAQ-prompt {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        .FAQ-nav {
            display: inline-block;
        }
        .tabset {
            display: block;
        } 
        .FAQ-prompt {
            line-height: 1.3;
            font-size: 18px;
        }
    }
}

@media (max-width: 767px) {
    .FAQs {
        color: black;
    }
}