@import '../variables.scss';

#website-title {
    color: $megBlue;
    font-weight: bold;
    text-decoration: none;
}

@media (max-width: 768px) {
    .header {
        position: fixed;
    }
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.banner {
    background-color: white;
    color: black;
    border-bottom: 1px solid grey;
    margin-right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-wrapper, .log-in-menu {
    flex-basis: 20%;
}

.log-in-menu {
    text-align: right;
    padding-right: 10px;
}

.logo {
    height: 60px;
    width: 60px;
    pointer-events: none;
    margin: 10px;
    cursor: pointer;
    border-radius: 10px;
    @media (max-width: '768px') {
        height: 40px;
        width: 40px;
    }
}


.banner .title {
    font-size: 32px;
    color: $megBlue;
    font-weight: 500;
    text-align: center;
    margin: auto;
}

.banner strong {
    position: relative;
}

#menu-toggle {
    margin-right: 10px;
    cursor: pointer;
    border: none;
    background: transparent;
    vertical-align: middle;
}

@media (min-width: '768px') {
    #menu-toggle {
        margin-right: 0px;
    }
}

.accounts {
    font-size: 20px;
    vertical-align: middle;
}

.hamburger {
    width: 40px;
    background-color: $megBlue;
    height: 4px;
    margin: 5px 0px;
    border-radius: 5px;
}