.team-photo {
    position: relative;
    width: 30%;
    height: 100%;
    display: block;
    margin: 1rem;
    &:hover {
        .overlay {
            height: 100%;
            border-radius: 5px;
            border: 1px solid white;
        }
    }
    .overlay {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0;
        width: 100%;
        background-color: #343942;
        z-index: 4;
        transition: 0.5s;
        overflow: hidden;
        line-height: 1.3;
        .member-name {
            font-weight: bold;
            padding-top: 5px;
            text-align: center;
        }
        .member-info {
            padding: 3px 5px;
        }
    }
    @media (max-width: '991px') {
        .overlay {
            font-size: 14px;
            .member-name {
                padding-top: 5px;
            }
        }
    }
    .member-photo {
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 100%;
        border: 1px solid black;
        border-radius: 5px;
    }
}

@media screen and (max-width: 767px) {
    .team-photo {
        width: 100%;
        margin: 1rem;
        color: white;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .team-photo {
        width: 45%;
        margin: 1rem;
    }
}