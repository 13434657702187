#account-panel {
    right: 0;
    text-align: left;
}

.profile-icon {
    width: 50%;
    margin: 10px 0px -10px 19%;
    @media (max-width: '767px') {
        margin: 10px auto -10px;
    }
}

#profile-name {
    text-align: center;
    padding-top: 0px;
}