.home-page {
    margin: auto;
    .home-image {
        img {
            border-radius: 10px;
            border: 1px solid black;
            margin-top: 10px;
            width: 100%;
        }
    }
    .gif {
        margin: 20px auto 0px auto;
    }
    hr {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    @media (max-width: '767px') {
        .home-image {
            padding: 0px;
            img {
                border-radius: 0;
            }  
        }
    }
    @media screen and (max-width: 991px) {
        .buttons {
            display: block;
        }
    }
}

.text {
    font-size: 20px;
    margin: auto;
}

@media (min-width: '768px') {
    .home-page {
        color: white;
    }
}

@media (max-width: '767px') {
    .text {
        font-size: 18px;
        line-height: 1.3;
    }
    .title-text {
        font-size: 25px;
    }
}

@media (max-width: '991px') {
    .text {
        margin-top: 15px;
    }
    .title-text {
        text-align: center;
    }
}

.modal-message {
    text-align: left;
    .beta-testing-list {
        padding-left: 15px;
    }
}

.get-the-app {
    margin-top: 15px;
    text-align: center;
    a img {
        height: 60px
    }
}