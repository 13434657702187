.post {
    margin-bottom: 1rem;
    .post-title {
        display: inline;
    }
    .author {
        font-style: italic;
        color: black;
    }
    .post-divider {
        margin: 10px -15px 5px;
        background: black;
        height: 1px;
    }
    a {
        color: black;
    }
    .edit-menu {
        position: relative;
        display: block;
        float: right;
        svg {
            cursor: pointer;
            margin-left: 3px;
            margin-bottom: 3px;
        }
    }
    .comment-section {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    background: white;
    padding: 10px 15px 5px 15px;
    z-index: -1;
    border: 1px solid black;
    border-radius: 7px;
    color: black;
    .post-date {
        float: right;
    }
    .post-body {
        white-space: pre-line;
        font-size: 20px;
    }
    .post-footer a {
        color: black;
    }
    @media (max-width: '767px') {
        .post-body {
            font-size: 16px;
            line-height: 1.3;
        }
    }
    @media (max-width: '767px') {
        .edit-menu {
            display: grid;
        }
    }
}

.comment-icon {
    height: 28px;
    width: 28px;
    margin-right: 5px;
}

.comment-section {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .post-title {
        font-size: 20px;
    }
    .post{
        background: lightgrey;
    }
}