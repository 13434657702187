.shopping-list {
    margin-top: 20px;
    .shopping-list-title {
        text-align: center;
    }
    .spinner-grow {
        height: 5rem;
        width: 5rem;
    }
    .list-container {
        margin: 15px auto;
        width: 90%;
        .list-item {
            border: 1px solid grey;
            border-radius: 5px;
            padding: 3px 5px;
            margin: 8px auto;
            font-size: 20px;
            background-color: white;
            .list-checkbox {
                height: 25px;
                width: 25px;
                vertical-align: sub;
                margin-right: 2px;
            }
        }
        .add-item {
            color: black;
            border: 1px solid grey;
            border-radius: 5px;
            padding: 3px 5px;
            width: 77%;
            font-size: 20px;
            vertical-align: bottom;
        }
        .add-item-button {
            width: 22%;
            margin-left: 1%;
            border: 1px solid black;
            background-color: #28a745;
            color: white;
        }
    }
    @media (min-width: 768px) {
        .shopping-list-title {
            color: white;
        }
        .healthy-link {
            color: white;
        }
    }
}