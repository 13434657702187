@import '../../variables.scss';

.modal-body {
    text-align: center;
    justify-content: center;
    input {
        border: 0;
        margin: 5px;
        width: 200px;
    }
}

.account-input {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 3px;
    margin: 3px;
}

.account-icon {
    width: 50px;
    cursor: pointer;
    float: right;
}

.greeting {
    font-weight: 500;
    color: $megBlue;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}