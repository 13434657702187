.our-team {
    h1 {
        text-align: center;
        margin-bottom: 20px;
    }
    .photo-collection {
        justify-content: center;
        display: inline-flex;
        flex-wrap: wrap;
    }
    .mobile-info {
        color: black;
        position: fixed;
        bottom: 30px;
        left: 30px;
        padding: 5px 8px;
        background-color: white;
        border: 1px solid black;
        border-radius: 5px;
        z-index: 20;
    }
}