@import '../../variables.scss';

.mobile-header {
    background-color: $megBlue;
    border-bottom: 1px solid grey;
    z-index: 5;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    .mobile-title {
        font-weight: bold;
        font-size: 25px;
        margin: 10px auto;
    }
    .mobile-login, .side-menu {
        flex-basis: 10%;
    }
    #log-in {
        color: white;
    }
    .hamburger {
        background-color: white;
    }
}