.how-it-works {
    .section {
        .section-text {
            font-size: 20px;
            margin: 10px auto 20px;
        }
        @media (max-width: '767px') {
            .section-text {
                font-size: 18px;
                line-height: 1.3;
                margin-top: 20px;
            }
        }
        .person {
            font-style: italic;
        }
    }
}

@media (min-width: '768px') {
    .how-it-works {
        color: white;
    }
}