body {
    background: #0C6284;
    font-family: 'Roboto', sans-serif
}

@media (max-width: 767px) {
    body {
        background-color: white;
    }
}

.page-content {
    padding: 60px 15px 0px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 576px) {
    .page-content {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .page-content {
        max-width: 720px;
        padding: 81px 30px 0px;
    }
}

@media (min-width: 992px) {
    .page-content {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .page-content {
        max-width: 1140px;
    }
}

input:focus, textarea:focus, button:focus {
    outline: none;
}

ul {
    padding-left: 0px;
}

.spinner-grow {
    height: 5rem;
    width: 5rem;
}

.submit {
    background-color: #222;
    border: 1px white solid;
    color: white;
}

.submit:hover {
    background-color: white;
    border: 1px black solid;
    color: black;
}

.buttons {
    display: flex;
}

.buttons button {
    font-size: 20px;
    width: 288px;
    text-align: center;
}

.buttons .get-the-app {
    text-align: center;
    margin: 0px auto;
}

#learn-more, #get-the-app {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 20px;
}

.back {
    background-color: white;
    color: black;
    border: 1px solid black;
}

.back:hover {
    background-color: black;
    color: white;
    border: 1px solid white;
}

.email, .email:hover {
    color: inherit;
}