.about {
    color: white;
    margin-left: auto;
    margin-right: auto;
    .team-meg-header {
        margin: 30px 0px;
        text-align: center;
    }
    @media (max-width: 768px) {
        .about-us-header {
            text-align: center;
        }  
        .buttons {
            display: block;
            text-align: center;
        }
    }
    .contact-us-header {
        margin-top: 15px;
    }
}

@media (max-width: 767px) {
    .about {
        color: black;
    }
}