@import '../../../variables.scss';

@media screen and (max-width: 767px) {
    #menu-toggle {
        border: none;
        background: transparent;
        vertical-align: middle;
    }
}

.side-menu {
    vertical-align: middle;
    display: inline-block;
}

button.menu-link {
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
}

.menu {
    height: 100%; 
    width: 0px;
    position: fixed;
    z-index: 1;
    top: 0;
    background: rgba(255, 255, 255, 0.863);
    overflow-x: hidden;
    transition: 0.5s;
    .panel-title {
        font-weight: bold;
        border-bottom: 3px solid $megBlue;
        padding-top: 12px;
    }
    #profile-link {
        font-weight: bold;
        text-align: center;
        border-bottom: 3px solid $megBlue;
    }
    a, .menu-link {
        padding: 8px 8px 8px 12px;
        text-decoration: none;
        font-size: 20px;
        color: $megBlue;
        display: block;
        transition: 0.3s;
        border-bottom: 1px solid grey;
        white-space: nowrap;
        background: transparent;
    }
    #closebtn {
        position: absolute;
        top: -6px;
        right: 5px;
        font-size: 36px;
        margin-left: 50px;
        border: none;
        background: transparent;
        color: $megBlue;
    }
    @media screen and (max-width: '768px') {
        .panel-title {
            padding-top: 16px;
            height: 62px;
        }
        #closebtn {
            top: -2px;
        }
    }
    .blog-menu {
        background-color: rgba(230, 230, 230, 0.863); 
        overflow-y: hidden;
        height: 0;
        transition: 0.5s;
        a {
            padding: 8px 8px 8px 20px;
            transition: 0.3s;
            border-bottom: 1px solid darkgray;
        }
    }
    #blog-link::after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }
}

@media screen and (max-height: 450px) {
    .menu {
        a {
            font-size: 18px;
        }
        padding-top: 15px;
    }
}

@media screen and (min-width: 768px) {
    #nav-menu {
        right: 0;
        a {
            text-align: left;
        }
    }
    .side-menu {
        padding: 0px;
    }
}

@media screen and (max-width: 767px) {
    #nav-menu {
        left: 0;
    }
}